<template>
  <div class="sources-setting">
    <div class="header">
      <div class="style-title-setting-global">
        {{ $i18n.locale == 'fr' ? 'Gestion des Sources' : 'Source Management' }}
      </div>
      <v-btn
        class="btn-filter"
        dark
        color="#5C2DD3"
        @click.stop="handleClickBtnAddSources"
      >
        <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
        {{ $i18n.locale == 'fr' ? 'Ajouter une source' : 'Add source' }}
      </v-btn>
    </div>
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div class="header-team mt-3" v-else>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="name_source"
            clearable
            :loading="getSourceLoading"
            :prepend-inner-icon="'mdi-magnify'"
            :label="$t('search')"
            outlined
            :placeholder="
              `${$t('search')} ${
                $i18n.locale === 'fr' ? 'une source' : 'source'
              }`
            "
            :persistent-placeholder="true"
            dense
            hide-details
            color="#5C2DD3"
          ></v-text-field
        ></v-col>

        <v-spacer></v-spacer>
        <v-col>
          <div v-if="getSourceLoading" class="color-crm font-sz-12 font-text">
            {{ $t('loading') }}
          </div></v-col
        >
      </v-row>
      <v-list
        dense
        class="list-ensemble mt-3"
        v-if="computedSourceFilter && computedSourceFilter.length"
      >
        <v-list-item
          class="list-ensemble-item"
          v-for="(source, index) in computedSourceFilter"
          :key="source.id"
          :value="source.id"
          :class="{
            'border-top-solid': index == 0
          }"
        >
          <v-list-item-content>
            <v-list-item-title class="flex center">
              <div class="font-text font-sz-14 bold-700">
                {{ source.name_internal }}
              </div>
              <div class="source-tags mt-1">
                <span v-if="!source.id_pixel_h2o" class="source-tag error-tag">
                  <font-awesome-icon
                    class="img-h-12 mr-1"
                    icon="exclamation-circle"
                  />
                  <span> H2O EFFICIENCY</span>
                </span>
                <span
                  v-if="!source.id_pixel_vertuoz"
                  class="source-tag error-tag"
                >
                  <font-awesome-icon
                    class="img-h-12 mr-1"
                    icon="exclamation-circle"
                  />
                  VERTUOZ
                </span>
                <span v-if="!source.id_pixel_acf" class="source-tag error-tag">
                  <font-awesome-icon
                    class="img-h-12 mr-1"
                    icon="exclamation-circle"
                  />
                  ACF
                </span>
                <span
                  v-if="!source.id_pixel_fiber"
                  class="source-tag error-tag"
                >
                  <font-awesome-icon
                    class="img-h-12 mr-1"
                    icon="exclamation-circle"
                  />
                  FIBER ENERGY
                </span>
                <span v-if="!source.id_pixel_rse" class="source-tag error-tag">
                  <font-awesome-icon
                    class="img-h-12 mr-1"
                    icon="exclamation-circle"
                  />
                  RSE ENERGIE
                </span>
                <span v-if="!source.id_pixel_gse" class="source-tag error-tag">
                  <font-awesome-icon
                    class="img-h-12 mr-1"
                    icon="exclamation-circle"
                  />
                  GSE
                </span>
                <span v-if="source.active == 0" class="source-tag warning-tag">
                  <font-awesome-icon
                    class="img-h-12 mr-1"
                    icon="exclamation-triangle"
                  />
                  {{ $i18n.locale == 'fr' ? 'Désactivé' : 'Deactivated' }}
                </span>
              </div>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              class="icon-action mr-2"
              small
              outlined
              color="#5C2DD3"
              :title="$t('update')"
              @click.prevent.stop="handleUpdateClick(source)"
            >
              <font-awesome-icon class="img-h-19" icon="pencil-alt" />
            </v-btn>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn
              class="icon-action"
              :title="$t('deleted')"
              small
              outlined
              color="#5C2DD3"
              @click.prevent.stop="handleDeleteClick(source)"
            >
              <font-awesome-icon class="img-h-19" icon="trash-alt" />
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <div v-else class="div-aucun-list">
        <div class="titre">
          {{ $i18n.locale == 'fr' ? 'Aucune Source' : 'No source' }}
        </div>
      </div>
    </div>
    <!-- Add Deposit Modal -->
    <v-dialog v-model="addSourceModal" max-width="600" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale == 'fr' ? 'Ajouter une source' : 'Add source' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('addSourceModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="addSourceModal" class="mt-2">
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="sourceToAdd.name"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col class="margin-switch mr-3" cols="7">
                <v-switch
                  class="input-checkbox switch-bottom label-switch"
                  :label="
                    sourceToAdd.internal
                      ? $i18n.locale === 'fr'
                        ? 'Interne'
                        : 'Internal'
                      : $i18n.locale === 'fr'
                      ? 'Externe'
                      : 'External'
                  "
                  v-model="sourceToAdd.internal"
                  color="#5C2DD3"
                >
                </v-switch>
              </v-col>
              <v-col class="ml-3">
                <v-switch
                  class="input-checkbox switch-bottom label-switch"
                  :label="
                    sourceToAdd.active
                      ? $i18n.locale === 'fr'
                        ? 'Activé'
                        : 'Activated'
                      : $i18n.locale === 'fr'
                      ? 'Désactiver'
                      : 'Deactivate'
                  "
                  v-model="sourceToAdd.active"
                  color="#5C2DD3"
                >
                </v-switch>
              </v-col>
            </v-row>
            <v-row v-if="sourceToAdd.internal == 0">
              <v-col>
                <v-autocomplete
                  :label="$t('commercial_sedentaire')"
                  v-model="sourceToAdd.comm_sed_id"
                  :items="getProjectsUsersCommercialsSedentaires || []"
                  :placeholder="
                    $t('searchMsg', { msg: $t('commercial_sedentaire') })
                  "
                  :clearable="true"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  :persistent-placeholder="true"
                  return-object
                  item-text="full_name"
                  dense
                  item-value="id"
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option: $t('commercial_sedentaire')
                    })
                  "
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      $t('msgObligMsg', { msg: $t('commercial_sedentaire') })
                  ]"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <div class="mt-2 color-crm-bold">
                  {{
                    $i18n.locale === 'fr'
                      ? 'Connexion avec les régies de PIXEL:'
                      : 'Connection with PIXEL control panels:'
                  }}
                </div>
              </v-col>
              <v-col class="">
                <v-btn
                  small
                  fab
                  icon
                  @click="showPixel = !showPixel"
                  class="float-right"
                >
                  <v-icon class="color-crm-bold icon-flech" v-if="showPixel"
                    >mdi-chevron-down</v-icon
                  >
                  <v-icon class="color-crm-bold icon-flech" v-else
                    >mdi-chevron-right</v-icon
                  >
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="border-color-crm-bold"></v-divider>
            <v-row class="mt-2" v-if="showPixel">
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  :placeholder="$t('searchMsg', { msg: 'régie' })"
                  item-color="#5C2DD3"
                  v-model="sourceToAdd.id_pixel_h2o"
                  :items="regiesH2O || []"
                  :loading="regiesH2OLoading"
                  :persistent-placeholder="true"
                  label="H2O EFFICIENCY"
                  item-text="libelle"
                  dense
                  item-value="id"
                  :no-data-text="$t('noDataOptionMsg', { option: 'régie' })"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  v-model="sourceToAdd.id_pixel_vertuoz"
                  :items="regiesVERTUOZ || []"
                  :loading="regiesVERTUOZLoading"
                  :placeholder="$t('searchMsg', { msg: 'régie' })"
                  :persistent-placeholder="true"
                  label="VERTUOZ"
                  item-text="libelle"
                  dense
                  item-value="id"
                  :no-data-text="$t('noDataOptionMsg', { option: 'régie' })"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  v-model="sourceToAdd.id_pixel_acf"
                  :items="regiesACF || []"
                  :loading="regiesACFLoading"
                  :placeholder="$t('searchMsg', { msg: 'régie' })"
                  :persistent-placeholder="true"
                  label="ACF"
                  item-text="libelle"
                  dense
                  item-value="id"
                  :no-data-text="$t('noDataOptionMsg', { option: 'régie' })"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  v-model="sourceToAdd.id_pixel_fiber"
                  :items="regiesFIBER || []"
                  :loading="regiesFIBERLoading"
                  :placeholder="$t('searchMsg', { msg: 'régie' })"
                  :persistent-placeholder="true"
                  label="FIBER ENERGY"
                  item-text="libelle"
                  dense
                  item-value="id"
                  :no-data-text="$t('noDataOptionMsg', { option: 'régie' })"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  v-model="sourceToAdd.id_pixel_rse"
                  :items="regiesRSE || []"
                  :loading="regiesRSELoading"
                  :placeholder="$t('searchMsg', { msg: 'régie' })"
                  :persistent-placeholder="true"
                  label="RSE ENERGIE"
                  item-text="libelle"
                  dense
                  item-value="id"
                  :no-data-text="$t('noDataOptionMsg', { option: 'régie' })"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  v-model="sourceToAdd.id_pixel_gse"
                  :items="regiesGSE || []"
                  :loading="regiesGSELoading"
                  :placeholder="$t('searchMsg', { msg: 'régie' })"
                  :persistent-placeholder="true"
                  label="GSE"
                  item-text="libelle"
                  dense
                  item-value="id"
                  :no-data-text="$t('noDataOptionMsg', { option: 'régie' })"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getSourceLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getSourceError" class="error-msg">
              <ul v-if="Array.isArray(getSourceError)">
                <li v-for="(e, index) in getSourceError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getSourceError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="ajouterSource"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('addSourceModal')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Add Deposit Modal -->
    <!-- Update Deposit Modal -->
    <v-dialog v-model="updateSourceModal" max-width="600" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale == 'fr' ? 'Modifier une source' : 'Edit source' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('updateSourceModal')"
            :title="$t('close')"
            color=" #5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div class="loader-content" v-if="loadingOpen">
            <v-progress-circular
              indeterminate
              class="ml-3"
              color="#5C2DD3"
            ></v-progress-circular>
          </div>
          <v-form
            ref="updateSourceModal"
            class="mt-2"
            v-if="sourceToUpdate && !loadingOpen"
          >
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="sourceToUpdate.name"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col class="margin-switch mr-3" cols="7">
                <v-switch
                  class="input-checkbox switch-bottom label-switch"
                  :label="
                    sourceToUpdate.internal
                      ? $i18n.locale === 'fr'
                        ? 'Interne'
                        : 'Internal'
                      : $i18n.locale === 'fr'
                      ? 'Externe'
                      : 'External'
                  "
                  v-model="sourceToUpdate.internal"
                  color="#5C2DD3"
                >
                </v-switch>
              </v-col>
              <v-col class="ml-3">
                <v-switch
                  class="input-checkbox switch-bottom label-switch"
                  :label="
                    sourceToUpdate.active
                      ? $i18n.locale === 'fr'
                        ? 'Activé'
                        : 'Activated'
                      : $i18n.locale === 'fr'
                      ? 'Désactiver'
                      : 'Deactivate'
                  "
                  v-model="sourceToUpdate.active"
                  color="#5C2DD3"
                >
                </v-switch>
              </v-col>
            </v-row>

            <v-row v-if="sourceToUpdate.internal == 0">
              <v-col>
                <v-autocomplete
                  :label="$t('commercial_sedentaire')"
                  v-model="sourceToUpdate.commercial_sedentaire"
                  :items="getProjectsUsersCommercialsSedentaires || []"
                  :placeholder="
                    $t('searchMsg', { msg: $t('commercial_sedentaire') })
                  "
                  :clearable="true"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  :persistent-placeholder="true"
                  return-object
                  item-text="full_name"
                  dense
                  item-value="id"
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option: $t('commercial_sedentaire')
                    })
                  "
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      $t('msgObligMsg', { msg: $t('commercial_sedentaire') })
                  ]"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <div class="mt-2 color-crm-bold">
                  {{
                    $i18n.locale === 'fr'
                      ? 'Connexion avec les régies de PIXEL:'
                      : 'Connection with PIXEL control panels:'
                  }}
                </div>
              </v-col>
              <v-col class="">
                <v-btn
                  small
                  fab
                  icon
                  @click="showPixel = !showPixel"
                  class="float-right"
                >
                  <v-icon class="color-crm-bold icon-flech" v-if="showPixel"
                    >mdi-chevron-down</v-icon
                  >
                  <v-icon class="color-crm-bold icon-flech" v-else
                    >mdi-chevron-right</v-icon
                  >
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="border-color-crm-bold"></v-divider>
            <v-row class="mt-2" v-if="showPixel">
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  :placeholder="$t('searchMsg', { msg: 'régie' })"
                  item-color="#5C2DD3"
                  v-model="sourceToUpdate.id_pixel_h2o"
                  :items="regiesH2O || []"
                  :loading="regiesH2OLoading"
                  :persistent-placeholder="true"
                  label="H2O EFFICIENCY"
                  item-text="libelle"
                  dense
                  item-value="id"
                  :no-data-text="$t('noDataOptionMsg', { option: 'régie' })"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  v-model="sourceToUpdate.id_pixel_vertuoz"
                  :items="regiesVERTUOZ || []"
                  :loading="regiesVERTUOZLoading"
                  :placeholder="$t('searchMsg', { msg: 'régie' })"
                  :persistent-placeholder="true"
                  label="VERTUOZ"
                  item-text="libelle"
                  dense
                  item-value="id"
                  :no-data-text="$t('noDataOptionMsg', { option: 'régie' })"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  v-model="sourceToUpdate.id_pixel_acf"
                  :items="regiesACF || []"
                  :loading="regiesACFLoading"
                  :placeholder="$t('searchMsg', { msg: 'régie' })"
                  :persistent-placeholder="true"
                  label="ACF"
                  item-text="libelle"
                  dense
                  item-value="id"
                  :no-data-text="$t('noDataOptionMsg', { option: 'régie' })"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  v-model="sourceToUpdate.id_pixel_fiber"
                  :items="regiesFIBER || []"
                  :loading="regiesFIBERLoading"
                  :placeholder="$t('searchMsg', { msg: 'régie' })"
                  :persistent-placeholder="true"
                  label="FIBER ENERGY"
                  item-text="libelle"
                  dense
                  item-value="id"
                  :no-data-text="$t('noDataOptionMsg', { option: 'régie' })"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  v-model="sourceToUpdate.id_pixel_rse"
                  :items="regiesRSE || []"
                  :loading="regiesRSELoading"
                  :placeholder="$t('searchMsg', { msg: 'régie' })"
                  :persistent-placeholder="true"
                  label="RSE ENERGIE"
                  item-text="libelle"
                  dense
                  item-value="id"
                  :no-data-text="$t('noDataOptionMsg', { option: 'régie' })"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  v-model="sourceToUpdate.id_pixel_gse"
                  :items="regiesGSE || []"
                  :loading="regiesGSELoading"
                  :placeholder="$t('searchMsg', { msg: 'régie' })"
                  :persistent-placeholder="true"
                  label="GSE"
                  item-text="libelle"
                  dense
                  item-value="id"
                  :no-data-text="$t('noDataOptionMsg', { option: 'régie' })"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getSourceLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getSourceError" class="error-msg">
              <ul v-if="Array.isArray(getSourceError)">
                <li v-for="(e, index) in getSourceError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getSourceError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="modifierSource"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('updateSourceModal')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Update Deposit Modal -->
    <!-- Delete Deposit Modal -->
    <v-dialog v-model="deleteSourceModal" max-width="500" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale == 'fr' ? 'Supprimer une source' : 'Delete source'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('deleteSourceModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p>
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? 'la source' : 'the source'
              })
            }}
            <strong> {{ sourceToDelete ? sourceToDelete.name : '' }}</strong>
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getSourceLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getSourceError" class="error-msg">
              <ul v-if="Array.isArray(getSourceError)">
                <li v-for="(e, index) in getSourceError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getSourceError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="supprimerSource"
            :loading="getSourceLoading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('deleteSourceModal')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Delete Deposit Modal -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      sourceToAdd: {
        name: null,
        id_pixel_h2o: null,
        id_pixel_vertuoz: null,
        id_pixel_acf: null,
        id_pixel_fiber: null,
        id_pixel_rse: null,
        id_pixel_gse: null,
        active: true,
        internal: false,
        comm_sed_id: null
      },
      name_source: null,
      sourceToUpdate: null,
      sourceToDelete: null,
      regiesH2O: [],
      regiesH2OLoading: false,
      regiesVERTUOZ: [],
      regiesVERTUOZLoading: false,
      regiesACF: [],
      regiesACFLoading: false,
      regiesFIBER: [],
      regiesFIBERLoading: false,
      regiesRSE: [],
      regiesRSELoading: false,
      regiesGSE: [],
      regiesGSELoading: false,
      addSourceModal: false,
      deleteSourceModal: false,
      updateSourceModal: false,
      initLoading: true,
      loadingOpen: false,
      loading: false,
      showPixel: true
    }
  },
  methods: {
    ...mapActions([
      'addSource',
      'updateSource',
      'deleteSource',
      'getListSource',
      'resetErrorSource',
      'fetchUsersCommercialSedentaire',
      'fetchUsersCommercialsIte',
      'fetchPixelSources'
    ]),
    handleClickBtnAddSources() {
      this.addSourceModal = true
    },
    resetModal() {
      this.sourceToAdd = {
        name: null,
        id_pixel_h2o: null,
        id_pixel_vertuoz: null,
        id_pixel_acf: null,
        id_pixel_fiber: null,
        id_pixel_rse: null,
        id_pixel_gse: null,
        active: true,
        internal: false,
        comm_sed_id: null
      }
      this.showPixel = true
      this.sourceToUpdate = null
      this.sourceToDelete = null
      this.resetErrorSource()
    },
    closeDialog(ref) {
      this[ref] = false
      if (ref == 'updateSourceModal') {
        this.$refs.updateSourceModal.resetValidation()
      }
      if (ref == 'addSourceModal') {
        this.$refs.addSourceModal.resetValidation()
      }
      this.resetModal()
    },
    handleUpdateClick(source) {
      this.loadingOpen = true
      const sourceToUpdate = { ...source }
      if (
        sourceToUpdate.id_pixel_h2o &&
        this.regiesH2O &&
        this.regiesH2O.length
      ) {
        const regie = this.regiesH2O.find(
          e => e.id == sourceToUpdate.id_pixel_h2o
        )
        if (regie) {
          sourceToUpdate.id_pixel_h2o = regie
        }
      }
      if (
        sourceToUpdate.id_pixel_vertuoz &&
        this.regiesVERTUOZ &&
        this.regiesVERTUOZ.length
      ) {
        const regie = this.regiesVERTUOZ.find(
          e => e.id == sourceToUpdate.id_pixel_vertuoz
        )
        if (regie) {
          sourceToUpdate.id_pixel_vertuoz = regie
        }
      }
      if (
        sourceToUpdate.id_pixel_acf &&
        this.regiesACF &&
        this.regiesACF.length
      ) {
        const regie = this.regiesACF.find(
          e => e.id == sourceToUpdate.id_pixel_acf
        )
        if (regie) {
          sourceToUpdate.id_pixel_acf = regie
        }
      }
      if (
        sourceToUpdate.id_pixel_fiber &&
        this.regiesFIBER &&
        this.regiesFIBER.length
      ) {
        const regie = this.regiesFIBER.find(
          e => e.id == sourceToUpdate.id_pixel_fiber
        )
        if (regie) {
          sourceToUpdate.id_pixel_fiber = regie
        }
      }
      if (
        sourceToUpdate.id_pixel_rse &&
        this.regiesRSE &&
        this.regiesRSE.length
      ) {
        const regie = this.regiesRSE.find(
          e => e.id == sourceToUpdate.id_pixel_rse
        )
        if (regie) {
          sourceToUpdate.id_pixel_rse = regie
        }
      }
      if (
        sourceToUpdate.id_pixel_gse &&
        this.regiesGSE &&
        this.regiesGSE.length
      ) {
        const regie = this.regiesGSE.find(
          e => e.id == sourceToUpdate.id_pixel_gse
        )
        if (regie) {
          sourceToUpdate.id_pixel_gse = regie
        }
      }

      this.sourceToUpdate = { ...sourceToUpdate }
      this.updateSourceModal = true
      this.loadingOpen = false
    },
    handleDeleteClick(source) {
      this.sourceToDelete = source
      this.deleteSourceModal = true
    },
    async ajouterSource() {
      if (this.$refs.addSourceModal.validate()) {
        this.loading = true
        const response = await this.addSource({ source: this.sourceToAdd })
        if (response) {
          this.fetchPixelRegies()
          this.closeDialog('addSourceModal')
        }
        this.loading = false
      }
    },
    async modifierSource() {
      if (this.$refs.updateSourceModal.validate()) {
        this.loading = true
        const response = await this.updateSource({
          source: this.sourceToUpdate
        })
        if (response) {
          this.closeDialog('updateSourceModal')
        }
        this.loading = false
      }
    },
    async supprimerSource() {
      const response = await this.deleteSource({
        source: this.sourceToDelete
      })
      if (response) {
        this.closeDialog('deleteSourceModal')
      }
    },
    fetchPixelRegies() {
      this.regiesH2OLoading = true
      this.fetchPixelSources({ type: 'H2O_EFFICIENCY' }).then(data => {
        this.regiesH2O = data
        this.regiesH2OLoading = false
        if (
          this.sourceToUpdate &&
          this.sourceToUpdate.id_pixel_h2o &&
          typeof this.sourceToUpdate.id_pixel_h2o === 'string'
        ) {
          this.sourceToUpdate.id_pixel_h2o = this.regiesH2O.find(
            e => e.id == this.sourceToUpdate.id_pixel_h2o
          )
        }
      })
      this.regiesVERTUOZLoading = true
      this.fetchPixelSources({ type: 'VERTUOZ' }).then(data => {
        this.regiesVERTUOZ = data
        this.regiesVERTUOZLoading = false
        if (
          this.sourceToUpdate &&
          this.sourceToUpdate.id_pixel_vertuoz &&
          typeof this.sourceToUpdate.id_pixel_vertuoz === 'string'
        ) {
          this.sourceToUpdate.id_pixel_vertuoz = this.regiesVERTUOZ.find(
            e => e.id == this.sourceToUpdate.id_pixel_vertuoz
          )
        }
      })
      this.regiesACFLoading = true
      this.fetchPixelSources({ type: 'ACF' }).then(data => {
        this.regiesACF = data
        this.regiesACFLoading = false
        if (
          this.sourceToUpdate &&
          this.sourceToUpdate.id_pixel_acf &&
          typeof this.sourceToUpdate.id_pixel_acf === 'string'
        ) {
          this.sourceToUpdate.id_pixel_acf = this.regiesACF.find(
            e => e.id == this.sourceToUpdate.id_pixel_acf
          )
        }
      })
      this.regiesFIBERLoading = true
      this.fetchPixelSources({ type: 'FIBER_ENERGY' }).then(data => {
        this.regiesFIBER = data
        this.regiesFIBERLoading = false
        if (
          this.sourceToUpdate &&
          this.sourceToUpdate.id_pixel_fiber &&
          typeof this.sourceToUpdate.id_pixel_fiber === 'string'
        ) {
          this.sourceToUpdate.id_pixel_fiber = this.regiesFIBER.find(
            e => e.id == this.sourceToUpdate.id_pixel_fiber
          )
        }
      })
      this.regiesRSELoading = true
      this.fetchPixelSources({ type: 'RSE_ENERGIE' }).then(data => {
        this.regiesRSE = data
        this.regiesRSELoading = false
        if (
          this.sourceToUpdate &&
          this.sourceToUpdate.id_pixel_rse &&
          typeof this.sourceToUpdate.id_pixel_rse === 'string'
        ) {
          this.sourceToUpdate.id_pixel_rse = this.regiesRSE.find(
            e => e.id == this.sourceToUpdate.id_pixel_rse
          )
        }
      })
      this.regiesGSELoading = true
      this.fetchPixelSources({ type: 'GSE' }).then(data => {
        this.regiesGSE = data ? data : []
        this.regiesGSELoading = false
        if (
          this.sourceToUpdate &&
          this.sourceToUpdate.id_pixel_gse &&
          typeof this.sourceToUpdate.id_pixel_gse === 'string'
        ) {
          this.sourceToUpdate.id_pixel_gse = this.regiesGSE.find(
            e => e.id == this.sourceToUpdate.id_pixel_gse
          )
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      'getSources',
      'getSourceLoading',
      'getSourceError',
      'getProjectsUsersCommercialsSedentaires'
    ]),
    computedSourceFilter() {
      if (this.name_source) {
        return this.getSources.filter(item => {
          return this.name_source
            .toLowerCase()
            .split(' ')
            .every(name => item.name.toLowerCase().includes(name))
        })
      } else {
        return this.getSources
      }
    }
  },
  async mounted() {
    this.initLoading = true
    await this.getListSource()
    this.fetchUsersCommercialSedentaire()
    this.fetchUsersCommercialsIte()
    this.fetchPixelRegies()
    this.initLoading = false
  }
}
</script>
<style lang="scss" scoped>
.sources-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      height: 30px;
      min-width: 30px;
    }
  }
  .list-ensemble-item {
    border-top: 0px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    border-color: #d8d8d8 !important;
  }
  .list-ensemble {
    max-height: calc(100vh - 263px) !important;
    height: calc(100vh - 263px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .list-ensemble::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .list-ensemble::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .list-ensemble::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }
  .icon-exclamation {
    text-decoration: line-through;
    text-decoration-color: #e73e01;
  }
  //   .content-members {
  //     padding-top: 8px;
  //   }
  .list-member {
    margin: 9px 0px;
    li {
      margin-bottom: 6px;
    }
  }
  .card-list {
    box-shadow: unset !important;
  }
}
.stade-sous-stade-flex {
  position: relative;
  margin-left: 25px;
  margin-top: 10px;
  margin-bottom: 10px;

  label {
    font-size: 12px;
    font-weight: bold;
  }
}
.source-tags {
  display: flex;
  align-items: center;
  .source-tag {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    margin-left: 5px;
    &.warning-tag {
      display: flex;
      align-items: center;
      color: #f39f2d;
      background-color: #dc80011c;
      border: 1px solid #f18e06;
    }
    &.error-tag {
      display: flex;
      align-items: center;
      color: #ff1744;
      background-color: #ff174412;
      border: 1px solid #ff1744;

      // color: #ff6961;
      // background-color: #ff696133;
      // border: 1px solid #ff6961;
    }
  }
}
</style>

<style lang="scss">
.sources-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      width: 30px;
      height: 30px;
      min-width: 30px;
    }
  }
}
.label-check {
  .v-label {
    font-size: 13px !important;
    font-family: 'Montserrat', sans-serif;
  }
}
</style>
